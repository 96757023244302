import './instrument';
import React from 'react';
import ReactDOM from 'react-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import './index.css';
import Amplify from 'aws-amplify';
import reportWebVitals from './reportWebVitals';
import env from './utils/env';
import App from './App';

declare global {
  interface Window {
    Intercom: any;
    google: any;
  }
}

window.Intercom('boot', {
  app_id: env('RIVER_INTERCOM_APP_ID'),
});

const stripePromise = loadStripe(env('RIVER_STRIPE_PUBLISHABLE_KEY') || '');

Amplify.configure({
  Auth: {
    identityPoolId: env('RIVER_AWS_IDENTITY_POOL_ID'),
    region: env('RIVER_AWS_REGION'),
  },
  Storage: {
    AWSS3: {
      bucket: env('RIVER_AWS_BUCKET_NAME'),
      region: env('RIVER_AWS_REGION'),
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            cssSrc: 'https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap',
          },
        ],
      }}
    >
      <App />
    </Elements>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
