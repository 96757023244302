/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import axios from 'axios';
import { isEmpty } from 'ramda';
import moment from 'moment-timezone';
import { NavigateFunction } from 'react-router-dom';
import Cookies from 'js-cookie';
import env from '../utils/env';
import apigateway from '../utils/apigateway';
import { formatErrors, transformRequest, transformResponse } from '../utils/request';
import setCookie from '../utils/setCookie';
import { paths } from '../routes';
import setToLocalStorage from '../utils/setToLocalStorage';
import getFromLocalStorage from '../utils/getFromLocalStorage';
import { hasStateMatchingZipCode } from '../utils/getStateFromZipCode';
import { Answer, Note, QuestionAnswers, listenToLatestVisit } from './visitContext';
import {
  reportErrorToSentry,
  reportMessageToSentry,
  reportUserIdentityToSentry,
  clearCurrentUserOnSentry,
} from '../utils/reportToSentry';
import sleep from '../utils/sleep';
import US_STATES from '../utils/usStates';

axios.defaults.baseURL = env('RIVER_API_URL');

export type AuthToken = {
  userId: string;
  email: string;
  phoneNumber: string;
  expiresAt: string;
  token: string;
};

export type AuthUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  preferredPronouns: string;
  preferredName: string;
  employeeId: string;
  profilePicture: string;
  idImage: string;
  selfieImage: string;
  signedAvatar: string;
  avatarPlaceholderColor: string;
  description?: string;
  streetAddress?: string;
  apartment?: string;
  city?: string;
  state?: string;
  zipCode: string;
  brokerCode?: string;
  patientDetails?: {
    height?: number;
    weight?: number;
    emergencyContactFirstName?: string;
    emergencyContactLastName?: string;
    emergencyContactPhoneNumber?: string;
    dateOfBirth?: string;
    gender?: Sex;
    genderIdentity?: Gender;
    allergies?: string;
    currentMedication?: string;
    medicalHistory?: Array<Answer>;
    patientLabTests?: any;
    guides?: AssignedProvider[];
    extendedNotes?: Array<Note>;
  };
  createdAt?: string;
  updatedAt?: string;
  customerId?: string;
  coupon?: string;
  unreadCount?: number;
  subscription: AuthSubscription;
  latestVisit: QuestionAnswers | null;
  coordinates?: {
    latitude: number;
    longitude: number;
  };
  companies?: Array<Company>;
  intercomHmac?: string;
  insurance: {
    bin: string;
    cardBackUrl: string;
    cardFrontUrl: string;
    memberId: string;
    subscriberId: string;
    memberName: string;
    pcn: string;
    planName: string;
    provider: string;
    rxGroup: string;
  };
};

export type AuthSubscription = {
  availableSubscriptionPlans: SubscriptionPlan[];
  currentSubscriptionPlan: SubscriptionPlan;
  currentSubscriptionPlans: SubscriptionPlan[];
  expirationDate: string;
  last4Digits: string;
  postalCode: string;
  discount?: {
    couponName: string;
    amountOff: number;
    percentOff: number;
  };
};

export type SubscriptionPlan = {
  currency: string;
  id: string;
  monthlyPrice: number;
  monthlyPriceWithDiscount: number;
  name: string;
  nextChargeDate: string;
  trialDaysLeft: number;
};

export type AssignedProvider = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  profilePicture: string;
  signedAvatar: string;
  avatarPlaceholderColor: string;
  description: string;
  zipCode: string | number;
  roles?: Array<'CARE-HEALTH-GUIDE' | 'MENTAL-HEALTH-GUIDE'>;
  isAvailable: boolean;
  isAcceptingVisit: boolean;
  createdAt?: string;
  updatedAt?: string;
};

export type Sex = 'MALE' | 'FEMALE' | 'INTERSEX';

export type Gender =
  | 'MALE'
  | 'FEMALE'
  | 'NON-BINARY'
  | 'TRANSGENDER-MALE'
  | 'TRANSGENDER-FEMALE'
  | 'TRANSGENDER';

export type Company = {
  name: string;
  amountCoveredByEmployer: number;
  amountCoveredByEmployerPrimaryCare: number;
  amountCoveredByEmployerCompleteCare: number;
  amountCoveredByEmployerStarterCare: number;
  availableSubscriptionPlanTypes: Array<{ planType: string; amountCovered: number }>;
  discounts: any;
  isOfferingPayrollDeduction: boolean;
  familyPlanTypeformId: string;
  isOfferingPrimaryCare: boolean;
  isOfferingStarterCare: boolean;
  isOfferingCompleteCare: boolean;
  planPaymentOption: string;
};

type Alert = {
  message: string;
  type: 'SUCCESS' | 'ERROR' | 'WARNING' | 'INFO';
  show: boolean;
};

type AuthState = {
  user: AuthUser | null;
  status: 'IDLE' | 'LOADING' | 'RESOLVED' | 'REJECTED';
  alert: Alert;
  virtualClinicStatus: 'OPENED' | 'CLOSED' | 'UNAVAILABLE' | 'UNDER_MAINTENANCE' | 'NOT-ACCESSIBLE';
  modalState: 'CLOSED' | 'OPENED';
};

type AuthDispatch = (action: AuthAction) => void;

type AuthAction =
  | { type: 'SET_AUTH_USER'; payload: AuthUser | null }
  | {
      type: 'SET_AUTH_STATUS';
      payload: 'IDLE' | 'LOADING' | 'RESOLVED' | 'REJECTED';
    }
  | { type: 'SET_MODAL_STATE'; payload: 'CLOSED' | 'OPENED' }
  | {
      type: 'SET_VIRTUAL_CLINIC_STATUS';
      payload: 'OPENED' | 'CLOSED' | 'UNAVAILABLE' | 'UNDER_MAINTENANCE' | 'NOT-ACCESSIBLE';
    }
  | { type: 'SHOW_ALERT'; payload: Alert };

const initialState: AuthState = {
  user: null,
  status: 'IDLE',
  virtualClinicStatus: 'OPENED',
  alert: { message: '', type: 'INFO', show: false },
  modalState: 'CLOSED',
};

const AuthContext = React.createContext<[state: AuthState, dispatch: AuthDispatch] | undefined>(
  undefined,
);

AuthContext.displayName = 'AuthContext';

function authReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case 'SET_AUTH_USER':
      return {
        ...state,
        user: action.payload,
      };
    case 'SET_AUTH_STATUS':
      return {
        ...state,
        status: action.payload,
      };
    case 'SHOW_ALERT':
      return {
        ...state,
        alert: action.payload,
      };
    case 'SET_VIRTUAL_CLINIC_STATUS':
      return {
        ...state,
        virtualClinicStatus: action.payload,
      };
    case 'SET_MODAL_STATE':
      return {
        ...state,
        modalState: action.payload,
      };
    default:
      return state;
  }
}

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(authReducer, initialState);

  const value = React.useMemo(() => [state, dispatch], [state, dispatch]) as [
    state: AuthState,
    dispatch: AuthDispatch,
  ];

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`\`useAuth\` must be used within a AuthProvider`);
  }
  return context;
}

export async function syncAuthUser(dispatch: AuthDispatch) {
  dispatch({ type: 'SET_AUTH_STATUS', payload: 'LOADING' });

  try {
    const storedUser = JSON.parse(getFromLocalStorage('_river_usrid') || '{}') as AuthUser; // For now we get subscription data here

    const authUser = (await apigateway.get(`/user/profile`)) as any;

    const visits = await listenToLatestVisit();

    const mergedAuthUser = {
      ...authUser,
      latestVisit: visits,
      subscription: storedUser.subscription,
    } as AuthUser;

    dispatch({ type: 'SET_AUTH_STATUS', payload: 'RESOLVED' });

    setToLocalStorage('_river_usrid', JSON.stringify(mergedAuthUser));

    dispatch({ type: 'SET_AUTH_USER', payload: mergedAuthUser });
  } catch (error) {
    dispatch({ type: 'SET_AUTH_STATUS', payload: 'IDLE' });
  }
}

export function showAlert(
  dispatch: AuthDispatch,
  { show, type, message } = {} as Alert,
  autoDismiss = false,
) {
  dispatch({
    type: 'SHOW_ALERT',
    payload: { show, type, message },
  });

  if (autoDismiss) {
    setTimeout(
      () =>
        dispatch({
          type: 'SHOW_ALERT',
          payload: { show: false, type: 'INFO', message: '' },
        }),
      3000,
    );
  }
}

export async function autoLoadUnReadMessages(
  providerId: string,
): Promise<{ questionnaireId: string; messageCount: number }[] | undefined> {
  try {
    const conversations = (await apigateway.get(`/conversations/counters`)) as any;

    return await (conversations || [])
      .filter((fndConvo: any) => fndConvo?.lastMessageUserId === providerId)
      .map((fndConvo: any) => ({
        questionnaireId: fndConvo.patientQuestionnaireId || '',
        messageCount: fndConvo.unreadMessageCount || 0,
      }));
  } catch (error) {
    console.log('Error', error);
    return undefined;
  }
}

export async function verifyPhoneNumber(
  dispatch: AuthDispatch,
  phoneNumber: string,
  cb: () => void,
) {
  try {
    dispatch({ type: 'SET_AUTH_STATUS', payload: 'LOADING' });

    await apigateway.post('/auth/phone/send-code', {
      phoneNumber,
    });

    cb();

    dispatch({ type: 'SET_AUTH_STATUS', payload: 'RESOLVED' });
  } catch (error) {
    dispatch({ type: 'SET_AUTH_STATUS', payload: 'REJECTED' });

    const { config, message, status } = formatErrors(error);

    showAlert(dispatch, {
      show: true,
      message,
      type: 'ERROR',
    });
  }
}

export function signOut(dispatch: AuthDispatch) {
  dispatch({ type: 'SET_AUTH_USER', payload: null });
  Cookies.remove('_river_tokid');
  localStorage.removeItem('_river_usrid');
  localStorage.removeItem('_river_enroll_completed_modal_closed');
  localStorage.removeItem('_river_temp_rx_refills');
  window.Intercom('shutdown');
  clearCurrentUserOnSentry();

  window.location.replace(paths.LOGIN_URL_PATH);
}

export async function signIn(
  dispatch: AuthDispatch,
  navigate: NavigateFunction,
  phoneNumber: string,
  otp: string,
  destinationPath: string,
  brokerCode?: string,
  planType = 'essential',
) {
  try {
    dispatch({ type: 'SET_AUTH_STATUS', payload: 'LOADING' });

    const authToken = (await apigateway.post('/auth/phone/verify-code', {
      phoneNumber,
      verificationCode: otp,
    })) as AuthToken;

    if (authToken.phoneNumber !== phoneNumber) {
      dispatch({ type: 'SET_AUTH_STATUS', payload: 'REJECTED' });
      showAlert(dispatch, {
        show: true,
        message: `Sorry! Looks like the provided phone number, "${phoneNumber}" do not match our records.`,
        type: 'ERROR',
      });
      return;
    }

    const {
      data: { data: authUserData },
    } = await getAuthUser(authToken.token);

    const authUser = authUserData as AuthUser;

    const emptyPersonalDetails =
      isEmpty(authUser.firstName) && isEmpty(authUser.lastName) && isEmpty(authUser.email);

    const currentURL = new URL(window.location.href);

    if (
      emptyPersonalDetails ||
      isEmpty(authUser.zipCode) ||
      isEmpty(authUser.state) ||
      !hasStateMatchingZipCode(authUser.state, authUser.zipCode) ||
      [
        paths.JOIN_URL_PATH.replace(':planType', planType),
        paths.JOIN_BROKER_USER_URL_PATH,
      ].includes(currentURL.pathname)
    ) {
      navigate(paths.JOIN_PROFILE_URL_PATH.replace(':planType', planType), {
        replace: true,
        state: {
          authToken,
          authUser,
          brokerCode,
        },
      });

      if (currentURL.pathname === paths.LOGIN_URL_PATH) {
        dispatch({ type: 'SET_AUTH_STATUS', payload: 'REJECTED' });
        showAlert(dispatch, {
          show: true,
          message: 'Kindly provide us with your basic information before you continue.',
          type: 'INFO',
        });
      } else {
        dispatch({ type: 'SET_AUTH_STATUS', payload: 'RESOLVED' });
      }

      return;
    }

    const {
      data: { data: authSubscriptionData },
    } = await getAuthSubscription(authToken.token);

    const authSubscription = authSubscriptionData as AuthSubscription;

    const subscriptionStatus = getOngoingSubscriptionStatus(
      authSubscription.currentSubscriptionPlan,
    );

    if (subscriptionStatus === 'NO_SUBSCRIPTION') {
      navigate(paths.JOIN_CONFIRM_PLAN_URL_PATH.replace(':planType', planType), {
        replace: true,
        state: { authToken, authUser },
      });
      dispatch({ type: 'SET_AUTH_STATUS', payload: 'REJECTED' });
      showAlert(dispatch, {
        show: true,
        message: `Sorry! Looks like your subscription has been canceled or you're not on any plan.`,
        type: 'ERROR',
      });
      return;
    }

    if (subscriptionStatus === 'EXPIRED_SUBSCRIPTION') {
      const subscribedPlan = authSubscription.currentSubscriptionPlan;

      const ongoingPlanType = subscribedPlan.name.split(':')?.[0] || planType;

      navigate(paths.JOIN_SUBSCRIPTION_URL_PATH.replace(':planType', ongoingPlanType), {
        replace: true,
        state: { authToken, authUser, subscribedPlan },
      });

      dispatch({ type: 'SET_AUTH_STATUS', payload: 'REJECTED' });

      showAlert(dispatch, {
        show: true,
        message: `Sorry! Your "${subscribedPlan.name}", subscription has expired. Please renew!`,
        type: 'ERROR',
      });
      return;
    }

    const mergedAuthUser = {
      ...authUser,
      subscription: {
        availableSubscriptionPlans: authSubscription.availableSubscriptionPlans,
        currentSubscriptionPlan: authSubscription.currentSubscriptionPlan,
        currentSubscriptionPlans: authSubscription.currentSubscriptionPlans,
        expirationDate: authSubscription.expirationDate,
        last4Digits: authSubscription.last4Digits,
        postalCode: authSubscription.postalCode,
      },
    } as AuthUser;

    const expires = Math.ceil(moment.duration(moment(authToken.expiresAt).diff(moment())).asDays());

    setCookie('_river_tokid', JSON.stringify(authToken), expires);

    setToLocalStorage('_river_usrid', JSON.stringify(mergedAuthUser));

    subscribeToExternals(authUser);

    dispatch({ type: 'SET_AUTH_STATUS', payload: 'RESOLVED' });

    dispatch({ type: 'SET_AUTH_USER', payload: mergedAuthUser });

    if (!isEmpty(destinationPath)) {
      navigate(destinationPath);
      return;
    }

    navigate(paths.HOME_URL_PATH, { replace: true });
  } catch (error) {
    dispatch({ type: 'SET_AUTH_STATUS', payload: 'REJECTED' });

    const { config, message, status } = formatErrors(error);

    showAlert(dispatch, {
      show: true,
      message,
      type: 'ERROR',
    });
  }
}

export async function updateAuthUser(
  dispatch: AuthDispatch,
  navigate: NavigateFunction,
  authToken: AuthToken,
  payload: Partial<AuthUser>,
  isAnEmployee: boolean,
  cb: (value?: string) => void,
  action?: 'TRY-AGAIN' | 'TRY-AS-INDIVIDUAL',
  planType = 'essential',
) {
  try {
    dispatch({ type: 'SET_AUTH_STATUS', payload: 'LOADING' });

    await axios({
      method: 'patch',
      url: `/user`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${authToken.token}`,
      },
      data: payload,
      transformResponse,
      transformRequest,
    });

    const {
      data: { data: authUserData },
    } = await getAuthUser(authToken.token);

    const authUser = authUserData as AuthUser;

    const company = getCompany(authUser);

    if (isAnEmployee && !company && (!action || action === 'TRY-AGAIN')) {
      dispatch({ type: 'SET_AUTH_STATUS', payload: 'REJECTED' });
      cb('NON-EMPLOYEE'); // Callback to not show address field. But set to default
      return;
    }

    const {
      data: { data: authSubscriptionData },
    } = await getAuthSubscription(authToken.token);

    const authSubscription = authSubscriptionData as AuthSubscription;

    const subscriptionStatus = getOngoingSubscriptionStatus(
      authSubscription.currentSubscriptionPlan,
    );

    if (subscriptionStatus !== 'HAS_SUBSCRIPTION') {
      dispatch({ type: 'SET_AUTH_STATUS', payload: 'RESOLVED' });
      navigate(paths.JOIN_CONFIRM_PLAN_URL_PATH.replace(':planType', planType), {
        replace: true,
        state: {
          authToken,
          authUser,
          isAnEmployee: isAnEmployee && company,
          goBack: paths.JOIN_PROFILE_URL_PATH.replace(':planType', planType),
        },
      });
      return;
    }

    const mergedAuthUser = {
      ...authUser,
      subscription: {
        availableSubscriptionPlans: authSubscription.availableSubscriptionPlans,
        currentSubscriptionPlan: authSubscription.currentSubscriptionPlan,
        currentSubscriptionPlans: authSubscription.currentSubscriptionPlans,
        expirationDate: authSubscription.expirationDate,
        last4Digits: authSubscription.last4Digits,
        postalCode: authSubscription.postalCode,
      },
    } as AuthUser;

    const expires = Math.ceil(moment.duration(moment(authToken.expiresAt).diff(moment())).asDays());

    setCookie('_river_tokid', JSON.stringify(authToken), expires);

    setToLocalStorage('_river_usrid', JSON.stringify(mergedAuthUser));

    subscribeToExternals(authUser);

    dispatch({ type: 'SET_AUTH_STATUS', payload: 'RESOLVED' });

    dispatch({ type: 'SET_AUTH_USER', payload: mergedAuthUser });

    navigate(paths.HOME_URL_PATH, { replace: true });
  } catch (error) {
    dispatch({ type: 'SET_AUTH_STATUS', payload: 'REJECTED' });

    const { message } = formatErrors(error);

    showAlert(dispatch, {
      show: true,
      message,
      type: 'ERROR',
    });

    reportErrorToSentry(error, {
      clientAction: 'Adding profile details on onboarding.',
      phoneNumber: `${authToken.phoneNumber}`,
      userId: authToken.userId,
    });
  }
}

export async function subscribeAuthUser(
  dispatch: AuthDispatch,
  navigate: NavigateFunction,
  authToken: AuthToken,
  authUser: AuthUser,
  plan: SubscriptionPlan,
  paymentMethodId: string,
  discountCode: string,
) {
  try {
    dispatch({ type: 'SET_AUTH_STATUS', payload: 'LOADING' });

    const {
      data: { data: authSubscriptionData },
    } = await getAuthSubscription(authToken.token);

    let authSubscription = authSubscriptionData as AuthSubscription;

    if (!authSubscription?.currentSubscriptionPlan && plan.id) {
      await axios({
        method: 'patch',
        url: `/billing/subscription?stripe_api_version=${env('RIVER_STRIPE_VERSION')}`,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${authToken.token}`,
        },
        data: {
          payment_method_id: paymentMethodId,
          vat_number: '',
          discount_code: discountCode,
        },
        transformResponse,
      });

      await postAuthSubscription(authToken.token, plan.id);

      const {
        data: { data: authSubscribedUser },
      } = await getAuthSubscription(authToken.token);

      authSubscription = authSubscribedUser;
    }

    const subscriptionStatus = getOngoingSubscriptionStatus(
      authSubscription.currentSubscriptionPlan,
    );

    if (subscriptionStatus !== 'HAS_SUBSCRIPTION') {
      dispatch({ type: 'SET_AUTH_STATUS', payload: 'REJECTED' });
      showAlert(dispatch, {
        show: true,
        message: `Sorry! Issue subscribing you to our "${plan.name}". Please try again!`,
        type: 'ERROR',
      });

      reportMessageToSentry(
        'Expired Subscription on onboarding',
        {
          clientAction: `Issue subscribing you to "${plan.name}". Action in (authContext.tsx)`,
          phoneNumber: authToken.phoneNumber,
          userId: authToken.userId,
        },
        'error',
      );

      return;
    }

    const {
      data: { data: authUserData },
    } = await getAuthUser(authToken.token); // This is done to get the patients assigned guide

    const mergedAuthUser = {
      ...authUser,
      subscription: {
        availableSubscriptionPlans: authSubscription.availableSubscriptionPlans,
        currentSubscriptionPlan: authSubscription.currentSubscriptionPlan,
        currentSubscriptionPlans: authSubscription.currentSubscriptionPlans,
        expirationDate: authSubscription.expirationDate,
        last4Digits: authSubscription.last4Digits,
        postalCode: authSubscription.postalCode,
      },
      patientDetails: {
        ...authUser.patientDetails,
        guides: authUserData?.patientDetails?.guides,
      },
    } as AuthUser;

    const expires = Math.ceil(moment.duration(moment(authToken.expiresAt).diff(moment())).asDays());

    setCookie('_river_tokid', JSON.stringify(authToken), expires);

    setToLocalStorage('_river_usrid', JSON.stringify(mergedAuthUser));

    subscribeToExternals(authUser);

    dispatch({ type: 'SET_AUTH_STATUS', payload: 'RESOLVED' });

    await sleep(2000);

    dispatch({ type: 'SET_AUTH_USER', payload: mergedAuthUser });

    navigate(`${paths.HOME_URL_PATH}?new-enrollment=true`, {
      replace: true,
    });
  } catch (error) {
    dispatch({ type: 'SET_AUTH_STATUS', payload: 'REJECTED' });

    const { message } = formatErrors(error);

    showAlert(dispatch, {
      show: true,
      message: message.replace(/\([^\]]+\)/g, ''),
      type: 'ERROR',
    });

    reportErrorToSentry(error, {
      clientAction: 'Subscribing User To a Plan on onboarding.',
      phoneNumber: authToken.phoneNumber,
      userId: authToken.userId,
    });
  }
}

function getAuthUser(token: string) {
  return axios({
    method: 'get',
    url: `/user/profile`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    transformResponse,
  });
}

export function getAuthSubscription(token: string) {
  return axios({
    method: 'get',
    url: `/billing/subscription?stripe_api_version=${env('RIVER_STRIPE_VERSION')}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    transformResponse,
  });
}

const postAuthSubscription = (token: string, planId: string) => {
  return axios({
    method: 'post',
    url: `/billing/subscription?stripe_api_version=${env('RIVER_STRIPE_VERSION')}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: {
      subscription_plan_id: planId,
    },
    transformResponse,
  });
};

function getOngoingSubscriptionStatus(
  plan: SubscriptionPlan,
): 'NO_SUBSCRIPTION' | 'EXPIRED_SUBSCRIPTION' | 'HAS_SUBSCRIPTION' {
  if (!plan || !plan.id) {
    return 'NO_SUBSCRIPTION';
  }

  const nextChargeDate = moment(plan.nextChargeDate).startOf('day');
  if (
    (!nextChargeDate.isValid() || nextChargeDate.diff(moment().startOf('day'), 'days', true) < 0) &&
    plan?.monthlyPriceWithDiscount !== 0
  ) {
    return 'EXPIRED_SUBSCRIPTION';
  }

  return 'HAS_SUBSCRIPTION';
}

function getAvailablePlan(
  subscription: AuthSubscription,
  possiblePlan: string,
  user?: AuthUser,
): SubscriptionPlan {
  let plan: SubscriptionPlan | undefined;

  let company = null;

  if (user) {
    company = getCompany(user);
  }

  if (possiblePlan === 'complete' || (!possiblePlan && company?.isOfferingCompleteCare)) {
    plan = subscription.availableSubscriptionPlans.find(
      availablePlan => availablePlan.id === env('RIVER_COMPLETE_MONTHLY_SUB_PLAN_ID'),
    );
  } else if (possiblePlan === 'primary' || (!possiblePlan && company?.isOfferingPrimaryCare)) {
    plan = subscription.availableSubscriptionPlans.find(
      availablePlan => availablePlan.id === env('RIVER_PRIMARY_MONTHLY_SUB_PLAN_ID'),
    );
  } else if (possiblePlan === 'virtual') {
    plan = subscription.availableSubscriptionPlans.find(
      availablePlan => availablePlan.id === env('RIVER_VIRTUAL_MONTHLY_SUB_PLAN_ID'),
    );
  } else if (possiblePlan === 'starter') {
    plan = subscription.availableSubscriptionPlans.find(
      availablePlan => availablePlan.id === env('RIVER_STARTER_MONTHLY_SUB_PLAN_ID'),
    );
  } else if (company?.isOfferingPrimaryCare || company?.isOfferingCompleteCare) {
    plan = subscription.availableSubscriptionPlans.find(
      availablePlan => availablePlan.id === env('RIVER_PRIMARY_MONTHLY_SUB_PLAN_ID'),
    );

    if (!plan) {
      plan = subscription.availableSubscriptionPlans.find(
        availablePlan => availablePlan.id === env('RIVER_COMPLETE_MONTHLY_SUB_PLAN_ID'),
      );
    }
  }

  if (!plan) {
    plan = subscription.availableSubscriptionPlans.find(
      availablePlan => availablePlan.id === env('RIVER_STARTER_MONTHLY_SUB_PLAN_ID'),
    );
  }

  return plan as SubscriptionPlan;
}

export function locatedInANonSupportedState(state: string) {
  return ['South Carolina', 'Alaska', 'Arkansas', 'Hawaii', 'Missouri'].includes(
    US_STATES.find(fndState => fndState.value === state)?.label || '',
  );
}

export function hasCompletedAllRequiredProfileInfo(user: AuthUser) {
  return (
    user?.firstName &&
    user?.lastName &&
    user.email &&
    user?.patientDetails?.dateOfBirth &&
    user.patientDetails?.gender &&
    user?.patientDetails?.height &&
    user?.patientDetails?.weight &&
    user?.idImage
  );
}

export function hasACompletedMedicalHistoryInfo(user: AuthUser) {
  const hasAllergiesAndMedication = Boolean(
    user?.patientDetails?.medicalHistory?.find(
      fndMed => fndMed.questionKey === 'CURRENT_MEDICATION_OR_SUPPLEMENTS',
    ) &&
      user?.patientDetails?.medicalHistory?.find(
        fndMed => fndMed.questionKey === 'CURRENT_ALLERGIES',
      ),
  );

  return (
    user?.patientDetails?.medicalHistory &&
    user?.patientDetails?.medicalHistory?.length > 0 &&
    hasAllergiesAndMedication
  );
}

export function getCompany(user: AuthUser): Company | null {
  let company = null;

  if (user?.companies && user?.companies?.length > 0) {
    [company] = user.companies;
  }

  return company;
}

export function getSubscribedPlan(user?: AuthUser | null) {
  if (!user?.subscription?.currentSubscriptionPlan?.name) {
    return [];
  }
  const plan = user?.subscription?.currentSubscriptionPlan?.name.split(':');
  return plan;
}

function subscribeToExternals(authUser: AuthUser) {
  window.Intercom('boot', {
    app_id: env('RIVER_INTERCOM_APP_ID'),
    user_id: authUser.id,
    ...(authUser?.intercomHmac && { user_hash: authUser.intercomHmac }),
    name: `${authUser.firstName} ${authUser?.lastName}`,
    phone: authUser.phoneNumber,
    email: authUser.email,
    created_at: moment(authUser.createdAt).unix(),
  });

  reportUserIdentityToSentry({
    id: authUser.id,
  });
}

export { AuthProvider, useAuth };
