import React from 'react';
import { Button, Graphic, Icon, MarkdownHTML, Modal } from '../..';
import { ReactComponent as RiverLogo } from '../../../assets/images/image_river_logo.svg';
import ImageSeeDoctorOnline from '../../../assets/images/image_demo_see_doctor_online.png';
import ImageVisitType from '../../../assets/images/image_demo_visit_type.png';
import ImageVisitCompletion from '../../../assets/images/image_demo_visit_completion.png';
import ImageClinicAppointment from '../../../assets/images/image_demo_clinic_appointment.png';
import ImageSelectClinic from '../../../assets/images/image_demo_select_clinic.png';
import ImagePrescriptionDelivery from '../../../assets/images/image_demo_prescription_delivery.png';
import ImageLocalPharmacy from '../../../assets/images/image_demo_pharmacy.png';
import ImageLabTesting from '../../../assets/images/image_demo_lab_testing.png';
import ImageRiverHealthCard from '../../../assets/images/image_demo_river_health_card.png';
import ImageBenefitSummary from '../../../assets/images/image_demo_benefits_summary.png';
import { IconSprite } from '../../Icon/Icon';
import capitalize from '../../../utils/capitalize';

type ModalProps = {
  showModal: boolean;
  isAlreadyEnrolled?: boolean;
  plan?: string[];
  sponsor?: string;
  onCloseModal: () => void;
};

const DEMOS = [
  {
    topic: 'How To See A Doctor Online',
    steps: [
      {
        subtitle: 'STEP 1',
        title: 'Select “See a Doc Online”.',
        description: `Once you log in to your River Health account, you'll find a button labeled "See A Doctor Online." Click on it to start your virtual consultation with your healthcare provider.`,
        graphic: ImageSeeDoctorOnline,
      },
      {
        subtitle: 'STEP 2',
        title: 'Select a visit type.',
        description: `Select from a list of healthcare services, including birth control, sick visits, skin conditions, and more. Answer a few questions about your health and submit your visit.`,
        graphic: ImageVisitType,
      },
      {
        subtitle: 'STEP 3',
        title: 'Visit Completion',
        description: `Your healthcare provider will review your visit and may ask more clarifying questions. If prescriptions are necessary, we will send them to your local pharmacy or mail them to your doorstep.`,
        graphic: ImageVisitCompletion,
      },
    ],
  },
  {
    topic: 'How To Book A Clinic Appointment',
    steps: [
      {
        subtitle: 'STEP 1',
        title: 'Select "Get Clinic  Appointment"',
        description: `After signing into your River Health account, select "Get a Clinic Appointment" and select the type of appointment you need. You can choose options such as an Annual Physical, a Well Woman Exam, and Sick Visits.`,
        graphic: ImageClinicAppointment,
      },
      {
        subtitle: 'STEP 2',
        title: 'Select a Clinic',
        description: `Next, you will see a list of nearby clinics that accept River Health. Select your preferred clinic from the list and follow the instructions to book your appointment.`,
        graphic: ImageSelectClinic,
      },
    ],
  },
  {
    topic: 'How To Get Prescriptions',
    steps: [
      {
        subtitle: 'OPTION 1',
        title: 'Your prescriptions are delivered to your door.',
        description: `If your River Health provider writes a prescription, we will ship it to your doorstep at no additional cost. Once your prescription leaves the pharmacy, you will receive a text message with your tracking number.`,
        graphic: ImagePrescriptionDelivery,
      },
      {
        subtitle: 'OPTION 2',
        title: 'Pick up at your local pharmacy.',
        description: `To pick up your prescriptions at your local pharmacy, go to your Profile Page on the River Health app and select "Prescription Card." Show your virtual prescription card to the pharmacist to pick up your medication.`,
        graphic: ImageLocalPharmacy,
      },
    ],
  },
  {
    topic: 'How To Get Lab Testing',
    steps: [
      {
        subtitle: undefined,
        title: 'Lab Testing',
        description: `If you see a doctor in person, ask the doctor to fax your lab order to [+1 218-319-4638](tel:+1218-319-4638) or email it to [team@helloriver.com.](mailto:team@helloriver.com)<br><br>  
        Your River Health Advocate will set up your lab appointment. We will share your lab results with the doctor you saw in person and your River Health virtual primary care provider.`,
        graphic: ImageLabTesting,
      },
    ],
  },
  {
    topic: 'How To Use Your River Health Card',
    steps: [
      {
        subtitle: undefined,
        title: 'Using Your River Health Card',
        description: `Your River Health Debit Card is on the way. It takes 7-14 days to arrive.<br><br> 
        Use your River Health debit card to pay for all doctor's office visits. After you book your first appointment, you will receive instructions to activate your River Health debit card.`,
        graphic: ImageRiverHealthCard,
      },
    ],
  },
];

function WelcomePackage({ onCloseModal, showModal, isAlreadyEnrolled, plan, sponsor }: ModalProps) {
  const [screen, setScreen] = React.useState(1);
  const [currentTutorial, setCurrentTutorial] = React.useState('INTRO');
  const [demo, setDemo] = React.useState(DEMOS[0]);
  const [demoStep, setDemoStep] = React.useState(0);
  const tutorialScreens = ['INTRO', 'CONTENTS', 'DEMOS', 'CONTACT', 'SUMMARY']; // INTRO, CONTENTS, DEMOS, CONTACT, SUMMARY

  const currentDemoIndex = DEMOS.findIndex(fndDemo => fndDemo.topic === demo.topic);

  const getReadablePlanName = (tier?: string) => {
    switch (tier) {
      case 'complete':
        return 'River Health Complete';
      case 'essential':
        return 'River Health Essential';
      case 'legacy':
        return 'River Health Legacy';
      case 'virtual':
        return 'River Health Virtual';
      default:
        return 'River Health Essential';
    }
  };
  const handleTutorialSwitch = () => {
    if (currentTutorial === 'SUMMARY') {
      let docLink = 'https://drive.google.com/file/d/1wJPBTDkAK7tJ4CKQp2UvBYXyUHxgNJNr/view';

      if (plan?.includes('legacy') || plan?.includes('essential') || plan?.includes('virtual')) {
        docLink = 'https://drive.google.com/file/d/1wJPBTDkAK7tJ4CKQp2UvBYXyUHxgNJNr/view';
      }

      if (plan?.includes('complete')) {
        docLink = 'https://drive.google.com/file/d/1Asr18IPjOGsdkj-si_uNKrG0gSB1xlna/view';
      }

      // switch (plan) {
      //   case 'River Health Starter Plan':
      //   case 'Primary Care Plan':
      //   case 'Virtual Plan':
      //     docLink = 'https://drive.google.com/file/d/1wJPBTDkAK7tJ4CKQp2UvBYXyUHxgNJNr/view';
      //     break;
      //   case 'River Health Complete Plan':
      //     docLink = 'https://drive.google.com/file/d/1Asr18IPjOGsdkj-si_uNKrG0gSB1xlna/view';
      //     break;
      //   case 'Loon Health Starter Plan':
      //     docLink = 'https://drive.google.com/file/d/1BmHiD10Jawmj3XivrxZuv0_gTggkE4vd/view';
      //     break;
      //   case 'Loon Health Complete Plan':
      //     docLink = 'https://drive.google.com/file/d/1o-LQgTsZA8ytttEXKlUKJpIoW3U2pfjl/view';
      //     break;
      //   default:
      //     break;
      // }

      window.open(docLink, '_blank');
      onCloseModal();
      setScreen(1);
      setCurrentTutorial('INTRO');
      setDemo(DEMOS[0]);
      setDemoStep(0);
      return;
    }

    setScreen(fndScreen => fndScreen + 1);

    if (currentTutorial === 'DEMOS') {
      if (demo.steps.at(demoStep + 1)) {
        setDemoStep(fndDemoStep => fndDemoStep + 1);
        return;
      }

      // Before doing back to contents check if there's a demo
      if (DEMOS[currentDemoIndex + 1]) {
        setDemo(DEMOS[currentDemoIndex + 1]);
        setDemoStep(0);
        setCurrentTutorial('CONTENTS');
        return;
      }
    }

    const findIndex = tutorialScreens.findIndex(
      fndTutorialScreen => fndTutorialScreen === currentTutorial,
    );
    const nextTutorial = tutorialScreens.at(findIndex < 0 ? 0 : findIndex + 1);
    setCurrentTutorial(nextTutorial || currentTutorial);
  };

  return (
    <Modal show={showModal} onClose={isAlreadyEnrolled ? () => onCloseModal() : undefined}>
      <div className="w-full h-full rounded-sm laptop:w-[1000px] tablet:h-[650px] bg-[#F6F6F6]">
        <div className="relative flex flex-col w-full h-full px-8 pt-4 pb-9">
          <div className="flex flex-row mb-4 items-center">
            <RiverLogo className="w-[40px]" />
            <div className="ml-auto text-[13px]">
              <span className="font-semibold">{screen}/</span>
              <span className="text-[#8F969A]">17</span>
            </div>

            <div>
              <IconSprite
                id="icon-demo-trismallies"
                className="hidden tablet:block w-[15px] h-auto absolute right-[35%] top-[80px]"
              />

              <IconSprite
                id="icon-demo-starsmallies"
                className="w-[14px] h-auto absolute left-[25%] top-[50px]"
              />

              <IconSprite
                id="icon-demo-out-starsmallies"
                className="w-[14px] h-[15px] absolute right-[20%] top-[10px]"
              />

              <IconSprite
                id="icon-demo-zigsmallies"
                className="w-[23px] h-[16px] absolute left-[50%] top-[20px]"
              />

              <Graphic id="graphicDemoMaskBg01" className="absolute top-0 left-0" />

              <Graphic
                id="graphicDemoMaskBg02"
                className="hidden tablet:block absolute top-0 left-[20%]"
              />

              <Graphic
                id="graphicDemoMaskBg03"
                className="hidden tablet:block absolute top-0 right-0"
              />
            </div>
          </div>

          {currentTutorial === 'INTRO' && (
            <div className="flex flex-col justify-center items-center flex-1 text-center mobile:py-32">
              {isAlreadyEnrolled && (
                <>
                  <div>
                    <span className="text-river-deep-orange text-[13px] font-semibold">
                      ACTIVE PLAN
                    </span>

                    <h2 className="font-medium text-stone-500 text-[20px] tracking-[0.04em] leading-8">
                      {getReadablePlanName(plan?.[0])}
                    </h2>

                    {sponsor && (
                      <div className="relative w-fit mx-auto mt-1">
                        <span className="font-medium inline-flex text-sm leading-none">
                          {sponsor}
                        </span>
                      </div>
                    )}

                    {plan?.[1] && (
                      <p className="font-semibold text-sm">
                        {plan?.[1] === 'individual'
                          ? capitalize(plan[1])
                          : `Individual + ${capitalize(plan[1])}`}
                      </p>
                    )}
                  </div>

                  <hr className="w-[38%] border-river-deep-orange border-t-[1.3px] mt-3 mb-5" />
                </>
              )}

              <h1
                className="font-semibold text-river-deep-orange text-[34px] tablet:text-[40px] 
                leading-[40px] tablet:leading-[56px] tracking-[0.032em] mb-3"
              >
                {!isAlreadyEnrolled
                  ? 'Your enrollment is complete.'
                  : 'River Health Benefits Summary.'}
              </h1>

              <p className="w-full tablet:w-[50%] mx-auto text-[20px] leading-relaxed">
                {!isAlreadyEnrolled
                  ? 'Next, we are going to show you how to use your River Health membership.'
                  : 'We are going to show you how to use your River Health membership.'}
              </p>
            </div>
          )}

          {currentTutorial === 'CONTENTS' && (
            <div className="flex flex-col justify-center items-center flex-1 w-fit mx-auto mobile:pt-10 mobile:pb-16">
              <h3 className="font-semibold text-river-deep-orange text-xl mb-5 tablet:mb-3">
                Here is what we are going to cover:
              </h3>

              <div className="flex flex-col space-y-4 self-start text-sm tablet:text-[17px]">
                {DEMOS.map((fndDemo, index) => (
                  <p className="inline-flex items-center">
                    <span
                      className={`flex w-5 h-5 rounded-full ${
                        index < currentDemoIndex ? 'bg-[#72C16D]' : 'bg-[#DBE1DB]'
                      } flex-shrink-0 mr-3 items-center justify-center`}
                    >
                      <Icon
                        id="checkmark"
                        className={`${
                          index < currentDemoIndex ? 'text-white' : 'text-[#A7B4A7]'
                        } w-[10px] h-[10px]`}
                      />
                    </span>
                    <span
                      className={`${index < currentDemoIndex ? 'line-through text-[#B1B1B1]' : ''}`}
                    >
                      {fndDemo.topic}
                    </span>
                  </p>
                ))}
              </div>
            </div>
          )}

          {currentTutorial === 'DEMOS' && demo && (
            <div className="flex flex-col justify-center items-center flex-1 mobile:pt-20 mobile:pb-20">
              <h3 className="font-semibold text-river-deep-orange text-xl mb-6 tablet:mb-10">
                {demo.topic}
              </h3>

              <div className="flex flex-row w-full items-center justify-center tablet:w-[80%] mx-auto space-x-10">
                <div className="w-full tablet:w-[50%]">
                  {demo.steps.at(demoStep)?.subtitle && (
                    <span className="text-[#758A95]">{demo.steps.at(demoStep)?.subtitle}</span>
                  )}

                  <h4 className="text-[#0E86C7] font-semibold text-lg mb-1">
                    {demo.steps.at(demoStep)?.title}
                  </h4>

                  <MarkdownHTML
                    className="w-package-text"
                    content={demo.steps.at(demoStep)?.description || ''}
                  />
                </div>

                <div className="hidden tablet:block flex-1">
                  <img
                    src={demo.steps.at(demoStep)?.graphic}
                    alt="error graphic"
                    className="w-auto mx-auto"
                  />
                </div>
              </div>
            </div>
          )}

          {currentTutorial === 'CONTACT' && (
            <div className="flex flex-col justify-center items-center flex-1 text-center mobile:pt-10 mobile:pb-16">
              <h3 className="font-semibold text-river-deep-orange text-xl mb-3">How to Get Help</h3>

              <div className="text-[17px] mb-6">
                Have questions? Need help using our services? We’re here for you!
              </div>

              <div>
                <p className="text-[15px] font-semibold mb-5">
                  Our office hours are 8am - 5pm CT on Monday - Friday.
                </p>

                <div
                  className="flex flex-row flex-wrap justify-center items-center space-y-2 
                  tablet:space-y-0 tablet:space-x-6"
                >
                  <div>
                    <div
                      className="hidden tablet:flex justify-center items-center bg-white w-16 h-16 border-[0.5px] 
                      border-river-deep-orange/50 rounded-full mx-auto mb-2"
                    >
                      <IconSprite
                        id="icon-help-phone"
                        className="w-[37px] h-[39px] text-river-deep-orange"
                      />
                    </div>
                    <a
                      href="tel:(888) 814-6062"
                      className="text-river-deep-orange underline underline-offset-2"
                    >
                      (888) 814-6062
                    </a>
                    <p className="text-[15px]">Call / Text</p>
                  </div>

                  <div>
                    <div
                      className="hidden tablet:flex justify-center items-center bg-white w-16 h-16 border-[0.5px] 
                    border-river-deep-orange/50 rounded-full mx-auto mb-2"
                    >
                      <IconSprite id="icon-help-email" className="w-[37px] h-[39px]" />
                    </div>
                    <a
                      href="mailto:team@helloriver.com"
                      className="text-river-deep-orange underline underline-offset-2"
                    >
                      team@helloriver.com
                    </a>
                    <p className="text-[15px]">Email us</p>
                  </div>

                  <div>
                    <div
                      className="hidden tablet:flex justify-center items-center bg-white w-16 h-16 border-[0.5px] 
                      border-river-deep-orange/50 rounded-full mx-auto mb-2"
                    >
                      <IconSprite
                        id="icon-help-headset"
                        className="w-[37px] h-[39px] text-[#F6914F]"
                      />
                    </div>
                    <a
                      href="https://help.helloriver.com"
                      target="_blank"
                      className="text-river-deep-orange underline underline-offset-2"
                      rel="noreferrer"
                    >
                      help.helloriver.com
                    </a>
                    <p className="text-[15px]">Support articles </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {currentTutorial === 'SUMMARY' && (
            <div className="flex flex-col justify-center items-center flex-1 mobile:pt-20 mobile:pb-20">
              <h3 className="font-semibold text-river-deep-orange text-xl mb-10">
                Download Your Benefits Summary.{' '}
              </h3>

              <div className="flex flex-row items-center justify-center w-full tablet:w-[80%] mx-auto space-x-10">
                <div className="w-full tablet:w-[50%]">
                  <h4 className="text-[#0E86C7] font-semibold text-lg mb-1">Benefit Summary</h4>

                  <MarkdownHTML
                    className="w-package-text"
                    content={`Your benefits summary explains the services included in your River Health plan and the services River Health does NOT offer.<br><br>
                    We **highly recommend** reviewing your benefits summary.`}
                  />
                </div>

                <div className="hidden tablet:block flex-1">
                  <img src={ImageBenefitSummary} alt="error graphic" className="w-auto mx-auto" />
                </div>
              </div>
            </div>
          )}

          <div className="text-center w-full flex flex-col items-center">
            {currentTutorial === 'CONTENTS' && (
              <span className="text-xs mb-3 flex items-center justify-center">
                This will be fast, we promise.
                <Icon id="iconThumbsUp" className="w-5 -mt-2 ml-1" />
              </span>
            )}

            <Button
              label={currentTutorial !== 'SUMMARY' ? 'Continue' : 'Download Summary'}
              onClick={handleTutorialSwitch}
              className="!px-2 mb-3"
            />

            {(currentTutorial === 'INTRO' || currentTutorial === 'SUMMARY') && (
              <button
                type="button"
                onClick={() => {
                  onCloseModal();
                  setScreen(1);
                  setCurrentTutorial('INTRO');
                  setDemo(DEMOS[0]);
                  setDemoStep(0);
                }}
                className="text-river-deep-orange text-xs underline underline-offset-[4px]"
              >
                {currentTutorial !== 'SUMMARY'
                  ? 'No, I already know how to use River Health. (not recommended)'
                  : 'Dismiss'}
              </button>
            )}
          </div>

          {currentTutorial === 'INTRO' && (
            <div className="hidden tablet:block">
              <span
                className="w-[150px] h-[150px] border border-river-deep-orange bg-transparent 
              rounded-full absolute -bottom-[90px] -right-[80px]"
              />
              <span
                className="w-[150px] h-[150px] border border-river-deep-orange bg-transparent 
              rounded-full absolute -bottom-[120px] right-[0px]"
              />
            </div>
          )}

          {currentTutorial !== 'INTRO' && (
            <div className="hidden tablet:block">
              <span
                className="w-[150px] h-[150px] border  border-dashed border-river-deep-orange bg-transparent 
              rounded-full absolute -bottom-[90px] -left-[80px]"
              />
              <span
                className="w-[150px] h-[150px] border  border-dashed border-river-deep-orange bg-transparent 
              rounded-full absolute -bottom-[120px] left-[0px]"
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

WelcomePackage.defaultProps = {
  isAlreadyEnrolled: false,
  plan: undefined,
  sponsor: undefined,
};

export default WelcomePackage;
