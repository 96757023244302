export const PROFILE_PHOTO_FOLDER = 'avatar';

export const CHAT_FILE_FOLDER = 'chat';

export const CHAT_LIMIT = 30;

export const VISIT_STATUS = {
  COMPLETE: 'COMPLETED',
  PENDING: 'PENDING',
  UNANSWERED: 'IN-PROGRESS',
};

export const MML_COMPANY_LIST = [
  "Clark's Aspen",
  "Clark's Austin",
  'Clarks Houston',
  'Elizabeth St. Cafe',
  'Howards & Rosies',
  "Jeffrey's & Josephine House",
  "Joann's",
  "June's",
  'La Embajada',
  'Lamberts',
  'Las Montanas',
  'Louis Swiss',
  "Lou's Barton Springs",
  "Lou's Eastside",
  'Neighborhood Sushi',
  'Pecan Square Cafe',
  "Perla's",
  'Pool Burger',
  "Sammie's Italian",
  'Ski Shores Cafe',
  'Swedish Hill',
  'Word of Mouth',
];
