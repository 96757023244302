import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import env from './utils/env';

Sentry.init({
  dsn: env('RIVER_SENTRY_DSN'),
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^\//, /^https:\/\/v3\.api\.helloriver\.com/],
  replaysSessionSampleRate: 0.1,
  // replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});
